<template>
  <div>
    <label
      v-if="label"
      class="inline-flex items-center text-sm font-medium leading-6 text-gray-900 w-full"
      >{{ label
      }}<InformationCircleIcon
        v-if="tooltip"
        v-tooltip="tooltip"
        class="h-4 w-4 shrink-0 ml-1"
    /></label>
    <Menu
      as="div"
      :class="[
        'relative inline-block text-left',
        buttonFullWidth ? 'w-full' : '',
        label ? 'mt-1' : '',
      ]"
    >
      <MenuButton
        v-tooltip="hoverTooltip"
        :class="[
          'relative inline-flex gap-x-1.5 w-full whitespace-nowrap min-h-[2.25rem] rounded-md bg-white py-1.5 pl-3 pr-8 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-400 sm:leading-6',
          textSize,
          disabled ? 'cursor-not-allowed' : 'hover:bg-gray-50',
        ]"
        :disabled="disabled"
      >
        <span v-if="prefix" class="font-semibold">{{ prefix }}</span>
        <span v-if="displayValue !== ''" class="block truncate">{{
          displayValue
        }}</span>
        <span v-else class="block truncate text-gray-400">
          {{ placeholder }}
        </span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <ChevronDownIcon
            class="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </MenuButton>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          :class="[
            'absolute z-10 w-72 max-h-72 overflow-y-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            textSize,
            optionsHeight != null ? optionsHeight : 'max-h-72',
            positionTop ? 'inset-x-0 bottom-10 mb-2' : 'mt-2',
            dropdownFullWidth ? 'w-full' : '',
          ]"
          data-cy="listbox-options"
        >
          <MenuItem v-slot="{}" @click.prevent>
            <div class="flex items-center w-full">
              <div
                v-tooltip="'Search'"
                class="px-4"
                :class="[
                  'block px-4 py-2 text-sm text-gray-700',
                  $slots.searchBarRight ? '' : 'w-full',
                ]"
              >
                <input
                  v-model="query"
                  type="text"
                  class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-400 sm:text-sm sm:leading-6"
                  placeholder="Search"
                  @keydown.space.prevent="onEnterSpace"
                />
              </div>
              <slot name="searchBarRight"></slot>
            </div>
          </MenuItem>
          <MenuItem
            v-if="canSelectAll"
            v-slot="{ active }"
            @click.prevent="onSelectAll"
          >
            <div
              :class="[
                active ? 'bg-primary-400 text-white' : 'text-gray-900',
                'flex items-center relative cursor-default select-none py-2 pl-5 pr-9 group w-full',
              ]"
            >
              <input
                :value="allSelected"
                :checked="allSelected"
                :true-value="true"
                :false-value="false"
                :disabled="disabled"
                type="checkbox"
                class="mr-2 h-4 w-4 rounded border-gray-300 text-primary-400 focus:ring-primary-400"
              />
              <span
                :class="[
                  allSelected ? 'font-semibold' : 'font-normal',
                  'block truncate',
                ]"
                >Select All</span
              >
            </div>
          </MenuItem>
          <MenuItem
            v-for="(option, idx) in filteredOptions"
            :key="getKey(option, idx)"
            v-slot="{ active }"
            @click.prevent="onSelect(option)"
          >
            <div
              :class="[
                active ? 'bg-primary-400 text-white' : 'text-gray-900',
                'flex justify-between items-center relative cursor-default select-none py-2 pl-5 pr-9 group w-full',
              ]"
            >
              <div class="flex items-center">
                <img
                  v-if="option.img"
                  :src="option.img"
                  :alt="option.displayValue"
                  class="h-5 w-5 flex-shrink-0 rounded-full mr-2"
                />
                <span
                  :class="[
                    isSelected(option) ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]"
                  >{{ option.displayValue }}</span
                >
              </div>

              <div class="flex items-center">
                <slot
                  name="optionMenu"
                  :option="option"
                  :selected="isSelected(option)"
                ></slot>
                <span
                  v-if="isSelected(option)"
                  :class="[
                    active ? 'text-white' : 'text-primary-400',
                    'absolute inset-y-0 right-0 flex items-center pr-5',
                  ]"
                >
                  <CheckCircleIcon class="h-4 w-4" aria-hidden="true" />
                </span>
              </div>
            </div>
          </MenuItem>
          <MenuItem v-slot="{}">
            <div class="px-4 block px-4 py-2 text-sm text-gray-700">
              <ButtonBase primary sm class="w-full" @click="onApply">
                Apply
              </ButtonBase>
            </div>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { CheckCircleIcon, ChevronDownIcon } from "@heroicons/vue/20/solid";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  label: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
  modelValue: {
    type: [Array] as PropType<string[] | number[]>,
    required: false,
    default: () => [],
  },
  options: {
    type: Array as PropType<
      Array<{
        value: any;
        displayValue: string;
        img?: string | null;
      }>
    >,
    required: true,
  },
  prefix: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
  tooltip: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
  hoverTooltip: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
  textSize: {
    type: String as PropType<string | null>,
    required: false,
    default: "sm:text-sm",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
    default: "Select option",
  },
  optionsHeight: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
  positionTop: {
    type: Boolean,
    required: false,
    default: false,
  },
  emitOnSelect: {
    type: Boolean,
    required: false,
    default: false,
  },
  canSelectAll: {
    type: Boolean,
    required: false,
    default: false,
  },
  buttonFullWidth: {
    type: Boolean,
    required: false,
    default: false,
  },
  dropdownFullWidth: {
    type: Boolean,
    required: false,
    default: false,
  },
  maxSelected: {
    type: Number,
    required: false,
    default: Infinity,
  },
});

watch(
  () => props.modelValue,
  (newValue) => {
    selectedIds.value = [...newValue];
  },
);

onMounted(() => {
  selectedIds.value = [...props.modelValue];
});

const query = ref<string>("");
const selectedIds = ref<Array<string | number>>([]);

const filteredOptions = computed(() => {
  return props.options.filter((option) => {
    return option.displayValue
      .toLowerCase()
      .includes(query.value.toLowerCase());
  });
});

const displayValue = computed(() => {
  const all = props.options
    .filter((option) => selectedIds.value.includes(option.value))
    .map((option) => option.displayValue);
  return all.length > 1
    ? `${all[0]} +${all.length - 1}`
    : all.length > 0
      ? all[0]
      : "";
});

const allSelected = computed(() => {
  return selectedIds.value.length === props.options.length;
});

const isSelected = (option: { value: any; displayValue: string }) => {
  return selectedIds.value.includes(option.value);
};

const getKey = (option: { value: any; displayValue: string }, idx: number) => {
  return `${option.value}-${idx}`;
};

// Acc. to Listbox docs, space selects value & closes listbox
// This is unwanted behaviour, when we want to search for a metric
// So, we prevent the default behaviour of space key and manually append it to the query
const onEnterSpace = () => {
  query.value = query.value + " ";
};

const emit = defineEmits(["update:modelValue"]);

const onSelectAll = () => {
  if (allSelected.value) {
    selectedIds.value = [];
  } else {
    selectedIds.value = props.options.map((option) => option.value);
  }
  if (props.maxSelected < selectedIds.value.length) {
    // Remove so many items from the start, that only maxSelected items are left
    selectedIds.value = selectedIds.value.slice(
      selectedIds.value.length - props.maxSelected,
    );
  }
  if (props.emitOnSelect) {
    emit("update:modelValue", selectedIds.value);
  }
};

const onSelect = (option: { value: any; displayValue: string }) => {
  if (selectedIds.value.includes(option.value)) {
    selectedIds.value = selectedIds.value.filter((id) => id !== option.value);
  } else {
    selectedIds.value = [...selectedIds.value, option.value];
  }
  if (props.maxSelected < selectedIds.value.length) {
    // Remove so many items from the start, that only maxSelected items are left
    selectedIds.value = selectedIds.value.slice(
      selectedIds.value.length - props.maxSelected,
    );
  }
  if (props.emitOnSelect) {
    emit("update:modelValue", selectedIds.value);
  }
};

const onApply = () => {
  emit("update:modelValue", selectedIds.value);
};
</script>
